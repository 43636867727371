import { render, staticRenderFns } from "./AddConfigurationProfiles.vue?vue&type=template&id=3517b981&scoped=true"
import script from "./AddConfigurationProfiles.vue?vue&type=script&lang=js"
export * from "./AddConfigurationProfiles.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3517b981",
  null
  
)

export default component.exports