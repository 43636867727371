import { render, staticRenderFns } from "./DeviceDetailsView.vue?vue&type=template&id=2e436330&scoped=true"
import script from "./DeviceDetailsView.vue?vue&type=script&lang=js"
export * from "./DeviceDetailsView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e436330",
  null
  
)

export default component.exports