import { render, staticRenderFns } from "./GlobalSearchBox.vue?vue&type=template&id=d12a4d18&scoped=true"
import script from "./GlobalSearchBox.vue?vue&type=script&lang=js"
export * from "./GlobalSearchBox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d12a4d18",
  null
  
)

export default component.exports