import auth from "./auth";
import log from "./log";

const MCM_BASE = "https://modernclientmanagementapi.azurewebsites.net"; // PROD
//const MCM_BASE = "https://modernclientmanagementapi-beta.azurewebsites.net";
const MCM_SCOPES = ["api://dac89ebc-fdb0-4869-8f8a-cd51014c3081/access_as_user"];



let accessToken;

export default {
  // for test only
  async GetStatus() {
    let resp = await CallMcmApi("/api/status", "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetCountries() {
    let resp = await CallMcmApi("/api/countries", "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetCities() {
    let resp = await CallMcmApi("/api/cities", "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetCitiesByCountryId(id) {
    let resp = await CallMcmApi(`/api/countries/${id}/getcities`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetBusinessesByCityId(id) {
    let resp = await CallMcmApi(`/api/cities/${id}/getbusinesses`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetCity(id) {
    let resp = await CallMcmApi(`/api/cities/${id}`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetCountry(id) {
    let resp = await CallMcmApi(`/api/country/${id}`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetCountryByName(scope, country) {
    const countryEscaped = country.replace("'", "''");
    const filter = `?filter=displayName eq 'MCM_${scope}_${countryEscaped}'`;
    let resp = await CallMcmApi(`/api/mcmroles${filter}`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetRoles(search, scope = null, country = "") {
    const countryEscaped = country.replace("'", "''");
    const filter = scope
      ? `?filter=startsWith('MCM_${scope}_${countryEscaped}',displayName)`
      : `?filter=startsWith('MCM_Devices',displayName) or startsWith('MCM_Users',displayName)`;
    const searchFilter = search
      ? `${filter}&search="displayName:${search}"`
      : filter;
    let resp = await CallMcmApi(
      `/api/mcmroles${searchFilter}&orderby=displayName&pagesize=100`,
      "Get"
    );

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetRoleById(id) {
    let resp = await CallMcmApi(`/api/mcmroles?filter=id eq '${id}'`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetRelatedRole(id) {
    let resp = await CallMcmApi(`/api/mcmroles/${id}/relatedrole`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetAssignmentGroup(id) {
    let resp = await CallMcmApi(`/api/assignmentgroups/${id}`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetUsers(search, pageSize = 100) {
    let resp = await CallMcmApi(
      `/api/users?search="displayName:${search}" OR "userPrincipalName:${search}" OR "city:${search}" OR "department:${search}" OR "jobTitle:${search}"&pagesize=${pageSize}`,
      "Get"
    );

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetUserById(id) {
    let resp = await CallMcmApi(`/api/users?filter=id eq '${id}'`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetApplications() {
    let resp = await CallMcmApi(`/api/applications`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetConfigurationProfiles() {
    let resp = await CallMcmApi(`/api/configurationprofiles`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetAssignmentGroups(tag) {
    let resp = await CallMcmApi(
      `/api/assignmentgroups?assignmentGroupTag=${tag}`,
      "Get"
    );

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetPermissions() {
    let resp = await CallMcmApi(`/api/permissions`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetPermissionOfARole(id) {
    let resp = await CallMcmApi(`/api/permissions/${id}`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetMembers(id, transitive = false) {
    let route = transitive ? "transitivemembers" : "members";
    let resp = await CallMcmApi(`/api/mcmgroups/${id}/${route}`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetMemberOf(cfg = { id: null, transitive: false }) {
    if (!cfg.id) return;
    let route = cfg.transitive ? "transitivememberOf" : "memberOf";
    let resp = await CallMcmApi(`/api/mcmgroups/${cfg.id}/${route}`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetAllDevicesOfCountry(id, deviceType) {
    let resp = await CallMcmApi(
      `/api/countries/${id}/getdevices?devicetype=${deviceType}`,
      "Get"
    );

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async GetSolveITLocationsOfACountry(country) {
    let resp = await CallMcmApi(`/api/solveit/location/${country}`, "Get");

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async AddDeviceToBusinessRole(member, businessId) {
    return await CallMcmApi(`/api/businesses/${businessId}/adddevice`, "Post", {
      deviceId: member.id,
    });
  },
  async RemoveDeviceFromBusinessRole(member, businessId) {
    return await CallMcmApi(
      `/api/businesses/${businessId}/removedevice`,
      "Post",
      { deviceId: member.id }
    );
  },
  async AddUserToBusinessRole(member, businessId) {
    return await CallMcmApi(`/api/businesses/${businessId}/adduser`, "Post", {
      userId: member.id,
    });
  },
  async RemoveUserFromBusinessRole(member, businessId) {
    return await CallMcmApi(
      `/api/businesses/${businessId}/removeuser`,
      "Post",
      { userId: member.id }
    );
  },
  async addRoleToAssignmentGroup(member, groupId) {
    return await CallMcmApi(
      `/api/assignmentgroups/${groupId}/members?membertype=0`,
      "Post",
      { memberId: member.id }
    );
  },
  async removeRoleFromAssignmentGroup(member, groupId) {
    return await CallMcmApi(
      `/api/assignmentgroups/${groupId}/members?membertype=0`,
      "Delete",
      { memberId: member.id }
    );
  },
  async createCity(city, groupId) {
    return await CallMcmApi(`/api/cities`, "Post", {
      countryId: groupId,
      cityName: city,
    });
  },
  async deleteCity(groupId) {
    return await CallMcmApi(`/api/cities/${groupId}`, "Delete");
  },
  async createBusiness(business, groupId) {
    return await CallMcmApi(`/api/businesses`, "Post", {
      cityId: groupId,
      businessName: business,
    });
  },
  async deleteBusiness(groupId) {
    return await CallMcmApi(`/api/businesses/${groupId}`, "Delete");
  },
  async deleteAzureADDevice(device) {
    return await CallMcmApi(`/api/devices/${device.id}`, "Delete");
  },
};
//////////////////////////////////////////////////////
// Private functions ///////////
//

async function CallMcmApi(apiPath, method, body = null) {
  log.Info(`CallMcmApi: ${method} ${apiPath}`);
  accessToken = await auth.acquireToken(MCM_SCOPES);
  let resp;

  if (method != "Get") {
    resp = await fetch(`${MCM_BASE}${apiPath}`, {
      method: method,
      body: JSON.stringify(body),
      headers: {
        authorization: `Bearer ${accessToken}`,
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  } else {
    resp = await fetch(`${MCM_BASE}${apiPath}`, {
      method: method,
      headers: {
        authorization: `Bearer ${accessToken}`,
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  if (!resp.ok) {
    throw await resp.json();
  }

  return resp;
}
