import { render, staticRenderFns } from "./DeviceDetails.vue?vue&type=template&id=55434e83&scoped=true"
import script from "./DeviceDetails.vue?vue&type=script&lang=js"
export * from "./DeviceDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55434e83",
  null
  
)

export default component.exports